<template>
  <div>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <div class="d-flex align-center">
        <v-tooltip
          bottom
          color="text base"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="white"
              icon
              :href="BULT_REDIRECT_URL"
              target="_blank"
              v-on="on"
            >
              <v-icon> mdi-lightbulb-on-outline </v-icon>
            </v-btn>
          </template>
          <span>Aktualności</span>
        </v-tooltip>
        <v-btn
          class="mr-2"
          icon
          disabled
        >
          <v-icon> mdi-bell-off-outline </v-icon>
        </v-btn>
        <v-select
          outlined
          hide-details
          dense
          append-icon="mdi-chevron-down"
          color="white"
          class="nav-button ml-2"
          style="width: 350px"
          return-object
          disabled
        >
          <template v-slot:append>
            <v-icon color="white"> mdi-chevron-down </v-icon>
          </template>
          <template v-slot:label>
            <span class="d-block text-subtitle-2 text--white select-title">{{
              $t('navigation.disabledWarehouseSelection')
            }}</span>
          </template>
        </v-select>
        <v-btn
          color="nav lighten-2"
          icon
          class="nav-button nav-button--single tile rounded mx-2"
          @click="onFullScreen"
        >
          <v-icon color="white">
            {{ `mdi-fullscreen${!isFullScreen ? '' : '-exit'}` }}
          </v-icon>
        </v-btn>
        <v-menu
          offset-y
          min-width="170"
          @input="userMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              color="nav lighten-2"
              v-on="on"
            >
              <v-icon
                size="25"
                color="white"
                style="flex: none"
              >
                mdi-account-circle-outline
              </v-icon>
              <div class="pl-2">
                <v-icon
                  color="white"
                  class="user-chevron-icon"
                  :class="userChevronIconUp && 'user-chevron-icon--rotate'"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon color="black"> mdi-account-circle-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ userName }}
              </v-list-item-title>
              <v-divider class="divider-user" />
            </v-list-item>
            <v-list-item
              link
              href="mailto:support@nubowms.pl"
            >
              <v-list-item-icon>
                <v-icon color="black"> mdi-alert-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Zgłoś błąd </v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="$router.push({ name: 'oidcLogout' })"
            >
              <v-list-item-icon>
                <v-icon color="black"> mdi-logout </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Wyloguj </v-list-item-title>
              <v-divider class="divider-user" />
            </v-list-item>

            <v-list-item
              link
              href="https://nubowms.pl/dokumentacja/"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon color="black"> mdi-help-circle-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Pomoc </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-else>
      <v-menu
        v-model="isOpenUserSettings"
        :close-on-content-click="true"
        offset-y
        min-width="170"
        @input="userMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-1"
            v-bind="attrs"
            icon
            color="white"
            v-on="on"
          >
            <v-icon
              size="25"
              color="white"
              style="flex: none"
            >
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon color="black"> mdi-account-circle-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ userName }}
            </v-list-item-title>
            <v-divider class="divider-user" />
          </v-list-item>
          <v-list-item
            link
            href="mailto:support@nubowms.pl"
          >
            <v-list-item-icon>
              <v-icon color="black"> mdi-alert-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title> Zgłoś błąd </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="$router.push({ name: 'oidcLogout' })"
          >
            <v-list-item-icon>
              <v-icon color="black"> mdi-logout </v-icon>
            </v-list-item-icon>
            <v-list-item-title> Wyloguj </v-list-item-title>
            <v-divider class="divider-user" />
          </v-list-item>

          <v-list-item
            link
            href="https://nubowms.pl/dokumentacja/"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon color="black"> mdi-help-circle-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title> Pomoc </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import AVATAR from '@/assets/images/avatar.jpg';

let checkingInternet;
const BULT_REDIRECT_URL = 'https://nubowms.pl/aktualnosci-w-nubowms/';

export default Vue.extend({
  name: '',
  data: () => ({
    BULT_REDIRECT_URL,
    isOpenUserSettings: false,
    internetConnectionSpeed: null,
    AVATAR,
    userChevronIconUp: false,
    isNewNotification: false,
    isNotificationsOpen: false,
    isFullScreen: false,
  }),
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    userName() {
      return this.oidcUser?.preferred_username;
    },
    currentLocale() {
      const locale = this.$i18n.locale === 'pl' ? 'en' : 'pl';
      return locale.toUpperCase();
    },
    internetConnection() {
      if (this.internetConnectionSpeed >= 10) {
        return {
          icon: 'mdi-speedometer',
          color: 'success',
          text: 'Dobre połączenie',
        };
      }
      if (this.internetConnectionSpeed >= 1.3) {
        return {
          icon: 'mdi-speedometer-medium',
          color: 'warning',
          text: 'Średnie połączenie',
        };
      }
      return {
        icon: 'mdi-speedometer-slow',
        color: 'danger',
        text: 'Słabe połączenie',
      };
    },
  },
  mounted() {
    if (window.navigator.connection) {
      checkingInternet = setInterval(() => this.checkInternetConnectionSpeed(), 5000);
    }
  },
  beforeDestroy() {
    clearInterval(checkingInternet);
  },
  methods: {
    userMenu(value) {
      this.userChevronIconUp = value;
    },
    changeLocale() {
      this.$i18n.locale = this.$i18n.locale === 'pl' ? 'en' : 'pl';
    },
    checkInternetConnectionSpeed() {
      this.internetConnectionSpeed = navigator.connection.downlink;
    },
    async onFullScreen() {
      const body = document.querySelector('body');
      const wasFullScreen = document.fullscreenElement;

      try {
        if (wasFullScreen) await document.exitFullscreen();
        if (!wasFullScreen) await body?.requestFullscreen();
      } catch (err) {
        console.warn('Nie udało się włączyć trybu pełnoekranowego.');
        console.warn(err);
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';

.avatar {
  width: 25px;
  border-radius: 50%;
}

.user {
  color: $white;
}

.user-chevron-icon {
  &--rotate {
    transform: rotate(180deg);
  }
}

.divider {
  border-color: $white !important;
}

.divider-user {
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--tile) .v-icon {
  color: $priority_gray !important;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.nav-button {
  background-color: $nav-lighten2;
  max-height: 40px !important;
  height: 40px !important;

  &--single {
    max-width: 40px !important;
    width: 40px !important;
  }
}
</style>
